<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       viewBox="0 0 84.42 72.02"
  >
    <g id="Calque_2"
       data-name="Calque 2"
    >
      <g id="grouper">
        <path class="cls-1"
              d="M69.58,40.28v6.1a3.67,3.67,0,1,1-7.33,0V8.54a8.55,8.55,0,0,0-17.09,0V63.47a3.66,3.66,0,0,1-7.32,0V28.32a8.55,8.55,0,0,0-17.09,0V46.38a3.66,3.66,0,1,1-7.32,0v-6.1a8.56,8.56,0,0,0-8.55-8.54H0v4.88H4.88a3.66,3.66,0,0,1,3.66,3.66v6.1a8.55,8.55,0,1,0,17.09,0V28.32a3.67,3.67,0,0,1,7.33,0V63.47a8.55,8.55,0,1,0,17.09,0V8.54a3.66,3.66,0,1,1,7.32,0V46.38a8.55,8.55,0,1,0,17.09,0v-6.1a3.66,3.66,0,0,1,3.66-3.66h6.3V31.74h-6.3A8.55,8.55,0,0,0,69.58,40.28Z"
        />
      </g>
    </g>
  </svg>
</template>

<style scoped>
.cls-1 {
  fill: #292828;
}
</style>
